import { ThemeOptions, createTheme } from '@mui/material/styles';

import { environment } from '../environment';

const theme = createTheme({
  typography: {
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: environment.config.primaryColor
      ? { main: environment.config.primaryColor }
      : {
          light: '#c2d5f3',
          main: '#326ac2',
          dark: '#0a429a',
        },
    secondary: {
      light: '#8eeac2',
      main: '#2ac682',
      dark: '#03825c',
      contrastText: '#fff',
    },
    black: {
      main: '#313131',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#326AC2',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
  },
} as ThemeOptions);

export default theme;
