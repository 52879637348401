import {
  FileInput,
  GISFile,
  GISService,
  Partner,
  TFile,
  gisFileStatuses,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { CloudDownload } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { environment } from '../../environment';

interface IValues {
  partnerCode: string;
  title: string;
  description: string;
}

type TProps = Omit<DialogProps, 'onClose'> & { onClose: () => void; partners: Partner[] };

function GISFilesDialog({ partners, ...props }: TProps): JSX.Element {
  const [partnersWithWritePermission] = useState(
    partners
      .filter(
        ({ dashboardTabsPermissions: { facilitiesGISFiles } }) => facilitiesGISFiles === 'write',
      )
      .sort((a, b) => a.title.localeCompare(b.title)),
  );
  const [files, setFiles] = useState<{ [key: string]: GISFile[] }>();
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    isSubmitting,
    values,
    touched,
    errors,
    setValues,
    setTouched,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: {
      partnerCode: '',
      title: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      partnerCode: Yup.string().required(),
      title: Yup.string().required(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.open) {
      resetForm();
      setFiles(undefined);
      init();
    } else {
      cancelPromises();
    }
  }, [props.open]);

  async function init() {
    try {
      const data = await cancellablePromise(
        Promise.all(partners.map(({ code }) => GISService.getFiles({ partnerCode: code }))),
      );

      setFiles(
        partners.reduce<{ [key: string]: GISFile[] }>((res, { code }, index) => {
          res[code] = data[index].sort((a, b) => (a.created.isBefore(b.created) ? 1 : -1));
          return res;
        }, {}),
      );
    } catch (err) {
      console.error(err);
    }
  }

  function resetForm() {
    setValues({ partnerCode: partnersWithWritePermission[0].code, title: '', description: '' });
    setTouched({ partnerCode: false, title: false, description: false });
    setFile(undefined);
  }

  async function onSubmit(
    { partnerCode, title, description }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!file) {
      setFileError(true);
      return;
    }

    setFileError(false);
    setSubmitting(true);

    try {
      const newFile = await GISService.sendFile({
        partnerCode,
        title,
        comment: description,
        document: file,
      });

      files?.[partnerCode].push(newFile);
      resetForm();
      enqueueSnackbar(t('bicycle_facilities.gis_files_dialog.form.sent'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('bicycle_facilities.gis_files_dialog.form.not_sent'), { variant: 'error' });
    }

    setSubmitting(false);
  }

  function handleFileChange(newFile?: TFile) {
    if (newFile === undefined || newFile instanceof File) {
      setFile(newFile);
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" scroll="paper" {...props}>
      <DialogTitle>
        <Trans i18nKey="bicycle_facilities.gis_files_dialog.title" />
      </DialogTitle>
      {files && partnersWithWritePermission.length > 0 && (
        <form onSubmit={handleSubmit}>
          <StyledFormCard>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <Typography>
                  <Trans i18nKey="bicycle_facilities.gis_files_dialog.form.title" />
                </Typography>
                <Typography color="textSecondary" component="p" variant="caption">
                  <Trans i18nKey="bicycle_facilities.gis_files_dialog.form.description" />
                </Typography>
              </Box>
              <FormGroup row sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl
                  required
                  disabled={isSubmitting}
                  margin="none"
                  size="small"
                  sx={{ width: 200 }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="partner-input">
                    <Trans i18nKey="bicycle_facilities.gis_files_dialog.form.partner" />
                  </InputLabel>
                  <Select
                    inputProps={{ id: 'partner-input' }}
                    label={<Trans i18nKey="bicycle_facilities.gis_files_dialog.form.partner" />}
                    name="partnerCode"
                    onChange={handleChange}
                    value={values.partnerCode}
                  >
                    {partnersWithWritePermission.map(({ id, code, title }) => (
                      <MenuItem key={id} value={code}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  disabled={isSubmitting}
                  error={touched.title && Boolean(errors.title)}
                  label={<Trans i18nKey="commons.title" />}
                  margin="none"
                  name="title"
                  onChange={handleChange}
                  size="small"
                  value={values.title}
                  variant="outlined"
                />
              </FormGroup>
              <TextField
                fullWidth
                multiline
                disabled={isSubmitting}
                error={touched.description && Boolean(errors.description)}
                id="description"
                label={<Trans i18nKey="commons.description" />}
                margin="none"
                name="description"
                onChange={handleChange}
                rows={2}
                size="small"
                value={values.description}
                variant="outlined"
              />
              <FileInput
                disabled={isSubmitting}
                error={fileError}
                file={file}
                onChange={handleFileChange}
                size="small"
                type="*"
              />
            </CardContent>
            <StyledFormCardActions>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="small"
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="bicycle_facilities.gis_files_dialog.actions.send" />
              </Button>
            </StyledFormCardActions>
          </StyledFormCard>
        </form>
      )}
      <StyledDialogContent>
        {partners
          .sort((a, b) => (a.isDefault ? -1 : b.isDefault ? 1 : a.title.localeCompare(b.title)))
          .map(({ code, title }, index) => (
            <Fragment key={code}>
              {index > 0 && <Divider />}
              <List dense subheader={<ListSubheader disableSticky>{title}</ListSubheader>}>
                {files ? (
                  files[code].length > 0 ? (
                    files[code].map(
                      ({ id, created, title, comment, status: _status, documentUrl }) => {
                        const status = gisFileStatuses[_status];

                        return (
                          <ListItem key={id}>
                            <ListItemAvatar>
                              <Tooltip
                                placement="right"
                                title={<Trans i18nKey={status.titleKey} />}
                              >
                                <StyledStatus color={status.color} />
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                              primary={title}
                              secondary={
                                <>
                                  <Trans
                                    i18nKey="bicycle_facilities.gis_files_dialog.sent_on"
                                    values={{ date: created.format('LL') }}
                                  />
                                  <br />
                                  {comment}
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Tooltip
                                placement="left"
                                title={<Trans i18nKey="commons.actions.download" />}
                              >
                                <IconButton
                                  component="a"
                                  href={`${environment.backendUrl}${documentUrl}`}
                                  rel="noreferrer"
                                  size="small"
                                  target="_blank"
                                >
                                  <CloudDownload />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      },
                    )
                  ) : (
                    <ListItem>
                      <Typography color="textSecondary" variant="caption">
                        <Trans i18nKey="bicycle_facilities.gis_files_dialog.no_file" />
                      </Typography>
                    </ListItem>
                  )
                ) : (
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton height={16} variant="circular" width={16} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton variant="text" width={100} />}
                      primaryTypographyProps={{ component: 'div' }}
                      secondary={<Skeleton variant="text" width={200} />}
                      secondaryTypographyProps={{ component: 'div' }}
                    />
                  </ListItem>
                )}
              </List>
            </Fragment>
          ))}
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={props.onClose} size="small">
          <Trans i18nKey="commons.actions.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledFormCard = styled(Card)`
  flex-shrink: 0;
`;

const StyledFormCardActions = styled(CardActions)`
  justify-content: flex-end;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: whitesmoke;
`;

const StyledStatus = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 8px;
  height: 16px;
  width: 16px;
`;

export default GISFilesDialog;
