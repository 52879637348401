import { SignInDialog } from '@geovelo-frontends/commons';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

import Content from './content';
import GISFilesDialog from './gis-files-dialog';
import Header from './header';

interface IProps {
  children: ReactNode;
}

function Layout({ children }: IProps): JSX.Element {
  const [gisFilesDialogOpen, openGISFilesDialog] = useState(false);
  const {
    user: { partners, signInDialogOpen },
    zone: { current: currentZone },
    actions: { setCurrentUser, openSignInDialog },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentZone === null) {
      const { maxAdministrativeLevel, defaultCountryCode, defaultZoneCode } = environment.config;

      if (maxAdministrativeLevel === 'world') navigate('stats');
      else if (maxAdministrativeLevel === 'country') navigate(`/${defaultCountryCode}/stats`);
      else navigate(`/${defaultCountryCode}/${defaultZoneCode}/stats`);
    }
  }, [currentZone]);

  return (
    <>
      <Helmet>
        <title>{environment.config.appTitle || t('bicycle_facilities.app_title')}</title>
      </Helmet>
      <Wrapper>
        <Header openGISFilesDialog={openGISFilesDialog} />
        <Content>{children}</Content>
      </Wrapper>
      <SignInDialog
        onClose={() => openSignInDialog(false)}
        onRegistered={(user) => {
          setCurrentUser(user);
          openSignInDialog(false);
        }}
        onSignedIn={(user) => {
          setCurrentUser(user);
          openSignInDialog(false);
        }}
        open={signInDialogOpen}
      />
      {partners && (
        <GISFilesDialog
          onClose={() => openGISFilesDialog(false)}
          open={gisFilesDialogOpen}
          partners={partners}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default Layout;
