import { CyclabilityZone, ShapefileService } from '@geovelo-frontends/commons';
import { GetApp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

interface IValues {
  projection: number | null;
  exportDataKey: number;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  cyclabilityZone: CyclabilityZone | null | undefined;
  onClose: () => void;
};

function DownloadDialog({ cyclabilityZone, onClose, ...props }: TProps): JSX.Element {
  const [downloading, setDownloading] = useState(false);
  const {
    user: { partners: userPartners },
    stats: { exportDataTypes, exportDataProjections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { values, setValues, handleChange, handleSubmit } = useFormik<IValues>({
    initialValues: {
      projection: 4326,
      exportDataKey: 0,
    },
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.open && exportDataTypes) {
      setDownloading(false);
      setValues({
        projection: exportDataProjections?.[0].srid || 4326,
        exportDataKey:
          exportDataTypes?.find(
            ({ model, type }) => model.code === 'DATA_GOUV' && type.code === 'BICYCLE_FACILITIES',
          )?.id || 0,
      });
    }
  }, [props.open, exportDataTypes]);

  async function onSubmit({ projection, exportDataKey }: IValues) {
    if (!cyclabilityZone) return;

    setDownloading(true);

    const data = exportDataTypes?.find(({ id }) => id === exportDataKey);
    if (!data) return;

    const partner = environment.config.partnerCode
      ? userPartners?.find(({ code }) => environment.config.partnerCode === code)
      : undefined;

    try {
      await ShapefileService.requestShapefile({
        partner,
        cyclabilityZoneId: cyclabilityZone.id,
        model: data.model.code,
        type: data.type.code,
        epsg: projection || undefined,
        format: 'SHAPEFILE',
      });

      enqueueSnackbar(t('bicycle_facilities.stats.download_dialog.request_sent'), {
        variant: 'success',
      });
      onClose();
    } catch {
      enqueueSnackbar(t('bicycle_facilities.stats.download_dialog.request_error'), {
        variant: 'error',
      });
    }

    setDownloading(false);
  }

  const exportDataType = exportDataTypes?.find(({ id }) => id === values.exportDataKey);

  return (
    <Dialog fullWidth maxWidth="xs" {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('bicycle_facilities.stats.download_dialog.title')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <FormControl disabled={downloading} margin="none">
              <FormLabel component="legend" id="export-data-label">
                {t('bicycle_facilities.stats.download_dialog.type')}
              </FormLabel>
              <Select
                labelId="export-data-label"
                name="exportDataKey"
                onChange={handleChange}
                size="small"
                value={values.exportDataKey}
              >
                {exportDataTypes?.map(({ id, title }) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {exportDataType?.description && (
              <Typography variant="caption">
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a href={decoratedHref} key={key} target="blank">
                      {decoratedText}
                    </a>
                  )}
                >
                  {exportDataType?.description}
                </Linkify>
              </Typography>
            )}
          </Box>
          <FormControl disabled={downloading} margin="none">
            <FormLabel component="legend" id="projection-label">
              {t('bicycle_facilities.stats.download_dialog.projection')}
            </FormLabel>
            <Select
              labelId="projection-label"
              name="projection"
              onChange={handleChange}
              size="small"
              value={values.projection}
            >
              {exportDataProjections?.map(({ id, srid, title }) => (
                <MenuItem key={id} value={srid}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={downloading}
            onClick={() => onClose()}
            size="small"
            type="reset"
            variant="outlined"
          >
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={downloading}
            size="small"
            startIcon={downloading ? <CircularProgress color="inherit" size={16} /> : <GetApp />}
            type="submit"
            variant="contained"
          >
            {t('commons.actions.download')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DownloadDialog;
