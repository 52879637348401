import {
  IEnvironment,
  TAdministrativeLevel,
  TLanguage,
  defaultEnvironment,
} from '@geovelo-frontends/commons';

import idfmLogo from './assets/images/idfm.svg';

let _environment: IEnvironment & {
  config: {
    appTitle?: string;
    defaultCountryCode: string;
    defaultLanguage?: TLanguage;
    defaultZoneCode: string;
    enableSHPDownload?: boolean;
    headerLogo?: string;
    maxAdministrativeLevel: Extract<TAdministrativeLevel, 'world' | 'country' | 'region'>;
    minAdministrativeLevel: Exclude<TAdministrativeLevel, 'world'>;
    resourceUrl?: string;
    partnerCode?: string;
    pdfDownload?: {
      administrativeLevel: TAdministrativeLevel;
      bucketUrl: string;
    };
    primaryColor?: string;
    useIDFMFacilitiesStyle?: boolean;
  };
} = {
  ...defaultEnvironment,
  source: '',
  apiKey: '3844e2ce-759a-4a43-b02b-b9b5630f2271',
  config: {
    maxAdministrativeLevel: 'country',
    minAdministrativeLevel: 'city',
    defaultCountryCode: 'fr',
    defaultZoneCode: 'île-de-france',
  },
};

if (process.env.REACT_APP_CONFIG === 'world') {
  _environment = {
    ..._environment,
    config: {
      ..._environment.config,
      maxAdministrativeLevel: 'world',
      minAdministrativeLevel: 'country',
    },
  };
} else if (process.env.REACT_APP_CONFIG === 'carto-velo') {
  _environment = {
    ..._environment,
    config: {
      ..._environment.config,
      appTitle: 'Carto Vélo | Île de France Mobilités',
      partnerCode: 'idfm',
      defaultLanguage: 'fr',
      primaryColor: '#25303b',
      maxAdministrativeLevel: 'region',
      minAdministrativeLevel: 'city',
      defaultCountryCode: 'fr',
      defaultZoneCode: 'île-de-france',
      headerLogo: idfmLogo,
      resourceUrl:
        "https://carto-velo.iledefrance-mobilites.fr/assets/pdf/Guide%20d'utilisation%20Site%20Cartovelo.pdf",
      enableSHPDownload: true,
      useIDFMFacilitiesStyle: true,
      pdfDownload: {
        administrativeLevel: 'epci',
        bucketUrl:
          !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development'
            ? 'https://backend-dev.geovelo.fr/idfm'
            : 'https://backend.geovelo.fr/idfm',
      },
    },
  };
}

export const environment = _environment;
