import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, IconButton, Skeleton, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AppContext } from '../../app/context';

interface IProps {
  openChildrenDialog: (open: boolean) => void;
}

function Header({ openChildrenDialog }: IProps): JSX.Element {
  const {
    zone: { childrenMap, current: currentZone },
    stats: { periods, selectedIndex },
    actions: { selectStatsIndex },
  } = useContext(AppContext);
  const { t } = useTranslation();

  function handlePrev() {
    if (periods && selectedIndex !== null && periods[selectedIndex - 1])
      selectStatsIndex(selectedIndex - 1);
  }

  function handleNext() {
    if (periods && selectedIndex !== null && periods[selectedIndex + 1])
      selectStatsIndex(selectedIndex + 1);
  }

  const period = (periods && selectedIndex !== null && periods[selectedIndex]) || null;

  return (
    <>
      <Toolbar sx={{ flexShrink: 0, gap: 2 }}>
        <Box alignItems="center" display="flex" flexShrink={0} gap={1}>
          <IconButton disabled={!period || selectedIndex === 0} onClick={handlePrev} size="small">
            <ChevronLeft />
          </IconButton>
          <PeriodWrapper>
            {period ? (
              <Typography whiteSpace="nowrap">{period.date.format('MMMM YYYY')}</Typography>
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </PeriodWrapper>
          <IconButton
            disabled={!period || (periods && selectedIndex !== null && !periods[selectedIndex + 1])}
            onClick={handleNext}
            size="small"
          >
            <ChevronRight />
          </IconButton>
        </Box>
        <Box alignItems="center" display="flex" flexGrow={1} gap={1} justifyContent="flex-end">
          {currentZone && childrenMap[currentZone.id] && (
            <Button
              color="primary"
              onClick={() => openChildrenDialog(true)}
              size="small"
              variant="outlined"
            >
              {t('bicycle_facilities.stats.actions.see_table')}
            </Button>
          )}
        </Box>
      </Toolbar>
    </>
  );
}

const PeriodWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 120px;
`;

export default Header;
