import {
  CyclewayIcon,
  Facilities,
  GreenwayIcon,
  LaneIcon,
  MixedFacilitiesIcon,
  OppositeIcon,
  SharedBuswayIcon,
} from '@geovelo-frontends/commons';
import { SvgIconProps } from '@mui/material';

const facilities: {
  key: Facilities | 'all';
  Icon?: (props: SvgIconProps) => JSX.Element;
  titleKey: string;
  descriptionKey?: string;
  osmLink?: string;
}[] = [
  {
    key: Facilities.Cycleways,
    Icon: CyclewayIcon,
    titleKey: 'commons.facilities.cycleway_other',
    descriptionKey: 'commons.facilities.cycleway_description',
    osmLink: 'https://wiki.openstreetmap.org/wiki/Tag:cycleway%3Dtrack',
  },
  {
    key: Facilities.Greenways,
    Icon: GreenwayIcon,
    titleKey: 'commons.facilities.greenway_other',
    descriptionKey: 'commons.facilities.greenway_description',
    osmLink: 'https://wiki.openstreetmap.org/wiki/Tag:highway%3Dpath',
  },
  {
    key: Facilities.Lanes,
    Icon: LaneIcon,
    titleKey: 'commons.facilities.lane_other',
    descriptionKey: 'commons.facilities.lane_description',
    osmLink: 'https://wiki.openstreetmap.org/wiki/Tag:cycleway%3Dlane',
  },
  {
    key: Facilities.Opposites,
    Icon: OppositeIcon,
    titleKey: 'commons.facilities.opposite_other',
    descriptionKey: 'commons.facilities.opposite_description',
    osmLink: 'https://wiki.openstreetmap.org/wiki/Tag:cycleway%3Dopposite_lane',
  },
  {
    key: Facilities.SharedBusways,
    Icon: SharedBuswayIcon,
    titleKey: 'commons.facilities.shared_busway_other',
    descriptionKey: 'commons.facilities.shared_busway_description',
    osmLink: 'https://wiki.openstreetmap.org/wiki/Tag:cycleway%3Dshare_busway',
  },
  {
    key: Facilities.MixedFacilities,
    Icon: MixedFacilitiesIcon,
    titleKey: 'commons.facilities.mixed_facilities_other',
    descriptionKey: 'commons.facilities.mixed_facilities_tooltip',
  },
];

export default facilities;
